import axios from "axios";
import GoogleMapReact from "google-map-react";
import MapMarker from "./mapMarker";

interface IProps {
  setGetLngLng: any;
  setFieldValue?: any;
  setAddress: any;
  latLng: any;
  setLoading?: any;
}

export default function CustomMap({
  setGetLngLng,
  setFieldValue,
  setAddress,
  latLng,
  setLoading,
}: IProps) {
  const defaultProps = {
    center: {
      lat: Number(latLng.lat),
      lng: Number(latLng.lng),
    },
    zoom: 10,
  };

  const fetchAddress = async (lat: number, lng: number) => {
    const url = `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${lat}&lon=${lng}`;
    setLoading(true);
    try {
      const response = await axios.get(url);
      const address = response.data?.display_name || "";
      setAddress(address);
      setFieldValue("address", address);
      setLoading(false);
    } catch (error) {
      console.error("Failed to fetch address:", error);
    }
  };

  return (
    <div style={{ height: "30vh", width: "100%" }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyAIcJXGmfpzfr1bSVhewjpMx17SdreO-4g" }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
        options={{
          fullscreenControl: false, // Hide the fullscreen button
        }}
        onClick={(e: any) => {
          const { lat, lng } = e;
          setGetLngLng({ lat, lng });
          setFieldValue("location", lat);
          fetchAddress(lat, lng);
        }}
      >
        <MapMarker lat={latLng.lat} lng={latLng.lng} />
      </GoogleMapReact>
    </div>
  );
}
