import React from "react";
interface IProps {
  lat: any;
  lng: any;
}

function MapMarker(props: IProps) {
  return (
    <img
      src="/assets/images/theme/map-pin.png"
      alt="Marker"
      height={45}
      width={45}
    />
  );
}

export default MapMarker;
