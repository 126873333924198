import React from "react";
import IMAGES from "../../../utils/constants/images";
import { INITIALVALUES, VALIDATIONS } from "../../../utils/validation";
import { useFormik } from "formik";
import CommonInput from "../../../components/common/commonInput";
import { numberOnly } from "../../../utils/helpers";
import { useLocation } from "react-router-dom";
import { IProfileDetail } from "../../../utils/interface";
import {
  useEditCompanyProfile,
  useGetIndustryList,
} from "../../../react-query/hooks/profile";
import { toast } from "react-custom-alert";
import { ClipLoader } from "react-spinners";
import PhoneInput from "react-phone-input-2";
import CustomIndustryDropdown from "../../../components/screens/profile/customIndustryDropdown";
import CustomCompanyTypeDropdown from "../../../components/screens/profile/customCompanyTypeDropDown";
import CustomMap from "../../../components/common/map";

const EditProfileDetail = () => {
  const fileInputRef = React.useRef<any>(null);
  const coverFileInputRef = React.useRef<any>(null);

  let location = useLocation();
  let profileDetailData: IProfileDetail = location?.state?.data;

  const companyTypeOptions = ["Private", "Non Profit", "Government"];
  const [profileImage, setProfileImage] = React.useState<any>(null);
  const [getLocation, setGetLocation] = React.useState<any>();
  const [loading, setLoading] = React.useState(false);

  const [coverImage, setCoverImage] = React.useState<any>(null);
  const [latLng, setLatLng] = React.useState<any>({
    lat: profileDetailData?.lat,
    lng: profileDetailData?.lng,
  });

  const handleCompanyTypeSelect = (selectedType: string) => {
    setFieldValue("companyType", selectedType);
  };

  const { mutateAsync, isLoading, isSuccess } = useEditCompanyProfile();
  const { data: getIndustryList } = useGetIndustryList({
    search: "",
  });

  React.useEffect(() => {
    if (isSuccess === true) {
      toast.success("Profile updated successfully");
    }
  }, [isSuccess]);

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    setValues,
  } = useFormik({
    initialValues: INITIALVALUES.companyProfileInitialValues,
    validationSchema: VALIDATIONS.companyProfileValidationSchema,
    onSubmit: async (values) => {
      let fromData = new FormData();
      try {
        fromData.append("profileId", profileDetailData._id);
        fromData.append("name", values.name);
        fromData.append("profile_address", values.address);
        fromData.append("description", values.description);
        fromData.append("pinCode", values.pinCode);
        fromData.append("email", values.email);
        fromData.append("website", values.website);
        fromData.append("phoneNumber", values.phoneNumber);
        fromData.append("countryCode", values.countryCode);
        fromData.append("socialLink", values.socialLink);
        fromData.append("profileImage", profileImage || values?.profileImage);
        fromData.append("coverImage", coverImage || values?.coverImage);
        fromData.append("companySize", values.companySize);
        fromData.append("companyType", values.companyType);
        fromData.append("industryType", values.industryType);
        fromData.append("yearFounded", values.yearFounded);
        fromData.append("lat", latLng.lat);
        fromData.append("lng", latLng.lng);
        await mutateAsync({ data: fromData });
      } catch (error: any) {
        if (error?.response?.status === 400) {
          toast.error(error?.response?.data?.message);
        }
      }
    },
  });

  React.useEffect(() => {
    if (profileDetailData) {
      setValues({
        address: profileDetailData?.profile_address,
        countryCode: profileDetailData?.countryCode.replace("+", ""),
        email: profileDetailData?.email,
        description: profileDetailData?.description,
        name: profileDetailData?.name,
        phoneNumber: profileDetailData?.phoneNumber,
        pinCode: profileDetailData?.pinCode,
        socialLink: profileDetailData?.socialLink,
        website: profileDetailData?.website,
        profileImage: profileDetailData?.profileImage,
        coverImage: profileDetailData?.coverImage,
        industryType: profileDetailData?.industryType,
        companySize: profileDetailData?.companySize,
        companyType: profileDetailData?.companyType,
        yearFounded: profileDetailData?.yearFounded,
      });
    }
  }, [profileDetailData, setValues]);

  // handle image select from input ref
  function handleSelectFromLibrary(value: string) {
    if (value === "profileImage") {
      if (fileInputRef.current) {
        fileInputRef.current.click();
      }
    } else {
      if (coverFileInputRef.current) {
        coverFileInputRef.current.click();
      }
    }
  }

  // handle image select when edit profile
  function handleImageChange(
    e: React.ChangeEvent<HTMLInputElement>,
    value: string
  ) {
    const file = e.target.files?.[0];
    if (value === "profileImage") {
      setProfileImage(file);
      setFieldValue("profileImage", file);
    } else {
      setCoverImage(file);
      setFieldValue("coverImage", file);
    }
  }

  return (
    <div className="container p-5 position-relative">
      <div className="row">
        <div className="col-12 title-btn-blk">
          <h1 className="company-title-txt">Edit Profile</h1>
          <button
            className="edit-btn fill"
            disabled={isLoading}
            onClick={() => handleSubmit()}
          >
            {isLoading ? <ClipLoader color="#ffffff" size={16} /> : "Save"}
          </button>
        </div>
      </div>
      <div className="cover-img-blk">
        {coverImage || values?.coverImage ? (
          <img
            className="cover-img"
            src={
              coverImage ? URL.createObjectURL(coverImage) : values.coverImage
            }
            alt="profileImg"
          />
        ) : (
          <img
            className="cover-img"
            src="https://www.cssoftsolutions.com/wp-content/themes/cssoft/assets/image/banner01.jpg"
            alt="cover-img"
          />
        )}

        <div className="cover-img-show">
          <input
            onChange={(e: any) => handleImageChange(e, "coverImage")}
            type="file"
            accept="image/*"
            className="d-none"
            ref={coverFileInputRef}
          />
          <label
            onClick={() => handleSelectFromLibrary("coverImage")}
            className="flex-column d-flex justify-content-center align-items-center"
          >
            Upload cover image
            <img width={25} src={IMAGES.editIcon} alt="edit" />
          </label>
        </div>
        {errors.coverImage && touched.coverImage && (
          <p
            className="error-text"
            style={{ position: "absolute", right: "-13px", bottom: "-30px" }}
          >
            {errors.coverImage}
          </p>
        )}
        <div className="img-icon-blk">
          {profileImage || values?.profileImage ? (
            <img
              className="profile-img"
              src={
                profileImage
                  ? URL.createObjectURL(profileImage)
                  : values.profileImage
              }
              alt="profileImg"
            />
          ) : (
            <img
              className="profile-img"
              alt="profileImg"
              src={IMAGES.defaultImage}
            />
          )}
          <span className="icon-blk">
            <input
              onChange={(e: any) => handleImageChange(e, "profileImage")}
              type="file"
              accept="image/*"
              className="d-none"
              ref={fileInputRef}
            />
            <label onClick={() => handleSelectFromLibrary("profileImage")}>
              <img
                className="img-inner-icon"
                src={IMAGES.editIcon}
                alt="edit"
              />
            </label>
          </span>
        </div>
        {errors.profileImage && touched.profileImage && (
          <p className="error-text mt-4">{errors.profileImage}</p>
        )}
      </div>
      <div className="row gx-5 gy-3 justify-content-center mt-4">
        <div className="col-sm-6">
          <CommonInput
            errors={errors.name}
            touched={touched.name}
            name="name"
            placeholder="Company Name"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.name}
          />
        </div>
        <div className="col-sm-6">
          <CustomIndustryDropdown
            options={getIndustryList?.data}
            selectedValue={values.industryType}
            onSelect={(industry: string) =>
              setFieldValue("industryType", industry)
            }
          />
          {errors.industryType && touched.industryType && (
            <p className="error-text ">{errors.industryType}</p>
          )}
        </div>
        <div className="col-sm-6">
          <CustomCompanyTypeDropdown
            options={companyTypeOptions}
            selectedValue={values.companyType}
            onSelect={handleCompanyTypeSelect}
          />
          {errors.companyType && touched.companyType && (
            <p className="error-text ">{errors.companyType}</p>
          )}
        </div>
        <div className="col-sm-6">
          <CommonInput
            errors={errors.address}
            name="address"
            touched={touched.address}
            placeholder="Address"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.address || getLocation}
          />
          {loading && (
            <p className="location-fetch-text">
              Fetching Location <ClipLoader color="#2b3241" size={10} />
            </p>
          )}
        </div>
        <div className="col-sm-6">
          <CommonInput
            errors={errors.companySize}
            name="companySize"
            touched={touched.companySize}
            placeholder="Company Size"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.companySize}
            onKeyPress={numberOnly}
          />
        </div>
        <div className="col-sm-6">
          <CommonInput
            errors={errors.yearFounded}
            name="yearFounded"
            touched={touched.yearFounded}
            placeholder="Founded Year"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.yearFounded}
            onKeyPress={numberOnly}
          />
        </div>
        <div className="col-sm-6">
          <CommonInput
            errors={errors.pinCode}
            max={6}
            onKeyPress={numberOnly}
            touched={touched.pinCode}
            name="pinCode"
            placeholder="Pin Code"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.pinCode}
          />
        </div>
        <div className="col-sm-6">
          <PhoneInput
            containerClass=" form-control common-input"
            placeholder="Mobile Number"
            value={`${values.countryCode}${values.phoneNumber}`}
            onChange={(value, data: any) => {
              const rawPhone = value
                .replace(/[^0-9]+/g, "")
                .slice(data?.dialCode?.length);
              setFieldValue("phoneNumber", rawPhone);
              setFieldValue("countryCode", data.dialCode);
            }}
          />
          {touched.phoneNumber && errors.phoneNumber && (
            <p className="error-text">{errors.phoneNumber}</p>
          )}
        </div>
        <div className="col-sm-6">
          <CommonInput
            type="email"
            errors={errors.email}
            touched={touched.email}
            name="email"
            placeholder="Email Address"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.email}
          />
        </div>
        <div className="col-sm-6">
          <CommonInput
            name="website"
            errors={errors.website}
            touched={touched.website}
            placeholder="Website"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.website}
          />
        </div>

        <div className="col-sm-6">
          <CommonInput
            errors={errors.socialLink}
            touched={touched.socialLink}
            name="socialLink"
            placeholder="Social Link"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.socialLink}
          />
        </div>
        <div className="col-sm-6">
          <textarea
            rows={3}
            className="form-control common-input"
            name="description"
            placeholder="Enter Description Here..."
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.description}
          />
          {errors.description && touched.description && (
            <p className="error-text">{errors.description}</p>
          )}
        </div>
        <div className="col-sm-12">
          <label className="input-label">Select Location</label>
          <CustomMap
            setLoading={setLoading}
            setAddress={setGetLocation}
            latLng={latLng}
            setGetLngLng={setLatLng}
            setFieldValue={setFieldValue}
          />
        </div>
      </div>
    </div>
  );
};

export default EditProfileDetail;
