import * as Yup from "yup";

const createCompanyProfileInitialValues = {
  description: "",
  website: "",
  socialLink: "",
  address: "",
  industryType: "",
  companyType: "",
  location: "",
};

const createCompanyProfileValidationSchema = Yup.object().shape({
  industryType: Yup.string().required("At least one industry is required*"),
  address: Yup.string().required("Address is required*"),
  description: Yup.string().required("Description is required*"),
  website: Yup.string()
    .required("Website link is required*")
    .test("is-valid-website", "Enter valid URL*", (value) => {
      const domainPattern = /^[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+$/;
      const httpsPattern = /^https:\/\/.+$/;
      const wwwPattern = /^www\.[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+$/;
      return (
        domainPattern.test(value) ||
        httpsPattern.test(value) ||
        wwwPattern.test(value)
      );
    }),
  // socialLink: Yup.string().required("Enter a valid social media link*"),
  companyType: Yup.string().required("At least one company type is required*"),
  location: Yup.string().required("location is required*"),
});

export {
  createCompanyProfileInitialValues,
  createCompanyProfileValidationSchema,
};
