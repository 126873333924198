/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import IMAGES from "../../../utils/constants/images";
import { useLocation, useNavigate } from "react-router-dom";
import { RoutePath } from "../../../routes/routePath";
import { IProfileDetail } from "../../../utils/interface";
import PhoneInput from "react-phone-input-2";
import axios from "axios";
import { ClipLoader } from "react-spinners";

const ProfileDetail = () => {
  let nav = useNavigate();
  let location = useLocation();
  const getData: IProfileDetail = location?.state?.data;

  const [getLocation, setGetLocation] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);

  const url = `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${getData?.lat}&lon=${getData?.lng}`;

  React.useEffect(() => {
    setLoading(true);
    axios
      .get(url)
      .then((res) => {
        setGetLocation(res.data.display_name);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <div className="container common-section p-5 position-relative">
      <div className="row">
        <div className="col-12 title-btn-blk">
          <h1 className="company-title-txt">Profile</h1>
          <button
            className="edit-btn"
            onClick={() =>
              nav(RoutePath.editProfileDetail.path, {
                state: {
                  data: getData,
                },
              })
            }
          >
            Edit Profile
          </button>
        </div>
      </div>
      <div className="cover-img-blk">
        <img
          className="cover-img"
          alt=""
          src={
            getData?.coverImage === null
              ? "https://www.cssoftsolutions.com/wp-content/themes/cssoft/assets/image/banner01.jpg"
              : getData?.coverImage
          }
        />
        <div className="img-icon-blk">
          {getData?.profileImage && (
            <img
              className="profile-img"
              alt=""
              src={
                getData?.profileImage
                  ? getData?.profileImage
                  : IMAGES.defaultImage
              }
            />
          )}
        </div>
      </div>
      <div className="row gx-5 gy-3 mt-4">
        <div className="col-sm-6">
          <input
            value={getData?.name}
            readOnly
            className="form-control common-input"
          />
        </div>
        <div className="col-sm-6">
          <input
            value={getData?.industryType}
            readOnly
            className="form-control common-input"
          />
        </div>
        <div className="col-sm-6">
          <input
            value={getData?.companyType}
            readOnly
            className="form-control common-input"
          />
        </div>
        <div className="col-sm-6">
          <input
            value={getData?.profile_address}
            readOnly
            className="form-control common-input"
          />
        </div>
        <div className="col-sm-6">
          <input
            value={getData?.companySize}
            readOnly
            className="form-control common-input"
          />
        </div>
        <div className="col-sm-6">
          <input
            value={getData?.yearFounded}
            readOnly
            className="form-control common-input"
          />
        </div>
        <div className="col-sm-6">
          <input
            value={getData?.pinCode || "---"}
            readOnly
            className="form-control common-input"
          />
        </div>
        <div className="col-sm-6">
          <PhoneInput
            disabled
            containerClass=" form-control common-input"
            value={`${getData.countryCode}${getData.phoneNumber}`}
          />
        </div>
        <div className="col-sm-6">
          <input
            value={getData?.email}
            readOnly
            className="form-control common-input"
          />
        </div>
        <div className="col-sm-6">
          <a
            target="_blank"
            href={
              getData?.website.includes("https://")
                ? getData?.website
                : `https://${getData?.website}`
            }
            className="form-control common-input link text-truncate"
          >
            {getData?.website}
          </a>
        </div>

        <div className="col-sm-6">
          {!getData?.socialLink ? (
            <input
              value={"---"}
              readOnly
              className="form-control common-input"
            />
          ) : (
            <a
              className="form-control common-input link text-truncate"
              target="_blank"
              href={
                getData?.socialLink.includes("https://")
                  ? getData?.socialLink
                  : `https://${getData?.socialLink}`
              }
            >
              {getData?.socialLink}
            </a>
          )}
        </div>
        {loading ? (
          <div className="col-sm-6">
            <p className="location-fetch-text">
              Fetching Location <ClipLoader color="#2b3241" size={10} />
            </p>
          </div>
        ) : (
          <div className="col-sm-6">
            <input
              value={getLocation}
              readOnly
              className="form-control common-input location"
              onClick={() =>
                window.open(
                  `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                    getLocation
                  )}`,
                  "_blank"
                )
              }
            />
          </div>
        )}

        <div className="col-sm-6">
          <textarea
            className="form-control common-input"
            value={getData.description}
            readOnly
          />
        </div>
      </div>
    </div>
  );
};

export default ProfileDetail;
